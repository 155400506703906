import 'owl.carousel'
import 'ion-rangeslider'
document.addEventListener('DOMContentLoaded', function() {
    let rtl = $('html').attr('dir') === 'rtl';
    $('.slides').owlCarousel({
        rtl: rtl,
        items: 1,
        dots: false,
        nav: false,
        loop: true,
        autoplay: true,
        lazyLoad:true,
    });
    $('.properties').owlCarousel({
        rtl: rtl,
        margin: 20,
        items: 3,
        dots: false,
        loop: true,
        nav: false,
        responsive:{
            0:{
                items:2,
                margin: 10,
            },
            576:{
                items:3,
                margin: 10,
            },
            767:{
                items:4,
                margin: 15,
            },
            992:{
                items:5,
            },
            1200:{
                items:6,
            }
        }
    });
    $('.articles').owlCarousel({
        rtl: rtl,
        margin: 40,
        items: 7,
        dots: true,
        stagePadding: 0,
        loop: true,
        nav: true,
        lazyLoad:true,
        autoplay: true,
        navText : ["<span class='long-arrow is-triangle arrow-bar is-left'></span>","<i class='long-arrow is-triangle arrow-bar is-right'></i>"],
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 2,
            },
            1200:{
                items:3,
                margin: 20,
            },
            1400:{
                items:3,
            }
        }
    });
    $('.projects').owlCarousel({
        rtl: rtl,
        margin: 25,
        dots: false,
        loop: true,
        lazyLoad:true,
        navText : ["<span class='long-arrow is-triangle arrow-bar is-left'></span>","<i class='long-arrow is-triangle arrow-bar is-right'></i>"],
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1200:{
                items:3,
            },
            1401:{
                items:4,
            }
        }
    });
    let $priceRange = $('.price-range-slider');
    $priceRange.ionRangeSlider({
        skin: "round",
        type: "double",
        hide_min_max: true,
        min: $priceRange.data('min-price'),
        max: $priceRange.data('max-price'),
        from: $priceRange.data('min-price'),
        to: $priceRange.data('max-price'),
    });

    let $areaRange = $('.area-range-slider');
    $areaRange.ionRangeSlider({
        skin: "round",
        type: "double",
        hide_min_max: true,
        min: $areaRange.data('min-area'),
        max: $areaRange.data('max-area'),
        from: $areaRange.data('min-area'),
        to: $areaRange.data('max-area'),
    });
});
